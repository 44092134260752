var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("v-progress-linear", {
        attrs: {
          active: _vm.loadingBarState,
          indeterminate: _vm.loadingBarState,
          absolute: "",
          top: "",
          color: "light-blue accent-4",
        },
      }),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }